import React, { useEffect, useState } from "react";
// import Link from "next/link";
import { useRouter } from "next/router";
import { FormattedMessage } from "react-intl";
import { baseUrl } from "api/constants";
import FtafatModal from "components/homePage/goodMorning/ftafatModal";
import { useWindowSize } from "api/commonConstants";

// import ClevertapReact from "utils/clevertapHelper";

const MobileMenuBottom = () => {
  const size = useWindowSize();

  const router = useRouter();
  const [activeTab, setActiveTab] = useState(
    router?.pathname.includes("/quiz")
      ? "quiz"
      : router?.pathname.includes("/short-video")
      ? "short-video"
      : router?.pathname.includes("/text")
      ? "text"
      : router?.pathname.includes("/audio")
      ? "audio"
      : router?.pathname.includes("/show")
      ? "show"
      : "all"
  );

  const { fatafat: openFatafat } = router.query;

  const [fatafatPosts, setFatafatPosts] = useState([]);
  const [scrollY, setScrollY] = useState(0);
  const [bottomBanner, setBottomBanner] = useState([]);
  const [closeFooterBanner, setCloseFooterBanner] = useState(false);

  const [shouldShow, setShouldShow] = useState(
    typeof openFatafat !== "undefined" && openFatafat === "true" ? true : false
  );
  const queryIndex = router.query?.index ? parseInt(router.query.index) : 0;
  const [firstScroll, setFirstScroll] = useState(false);

  function callGtag() {
    // window.googletag = window.googletag || { cmd: [] };
    // window.dataLayer = window.dataLayer || [];
    // function gtag() {
    //   dataLayer.push(arguments);
    // }
    // gtag("js", new Date());
    // gtag("config", "G-RXRPKR4310");
    // (function (w, d, s, l, i) {
    //   w[l] = w[l] || [];
    //   w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    //   var f = d.getElementsByTagName(s)[0],
    //     j = d.createElement(s),
    //     dl = l != "dataLayer" ? "&l=" + l : "";
    //   j.async = true;
    //   j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    //   f.parentNode.insertBefore(j, f);
    // })(window, document, "script", "dataLayer", "GTM-P9LRV6T");
    // const gtag1 = document.createElement("script");
    // gtag1.setAttribute(
    //   "src",
    //   "https://www.googletagmanager.com/gtag/js?id=UA-203175993-6"
    // );
    // gtag1.setAttribute("async", "true");
    // document.head.appendChild(gtag1);
    // const gtag2 = document.createElement("script");
    // gtag2.setAttribute(
    //   "src",
    //   "https://www.googletagmanager.com/gtag/js?id=G-RXRPKR4310"
    // );
    // gtag2.setAttribute("async", "true");
    // document.head.appendChild(gtag2);
  }

  useEffect(() => {
    router?.pathname.includes("/quiz")
      ? setActiveTab("quiz")
      : router?.pathname.includes("/short-video")
      ? "short-video"
      : router?.pathname.includes("/text")
      ? setActiveTab("text")
      : router?.pathname.includes("/audio")
      ? setActiveTab("audio")
      : router?.pathname.includes("/show")
      ? setActiveTab("show")
      : setActiveTab("all");
  }, [router?.pathname]);

  const [showFooter, setShowFooter] = useState(true);
  const [scrollDirection, setScrollDirection] = useState(null);

  // useEffect(()=>{
  //   setTimeout(()=>{
  //     setScrollDirection("up")
  //   },4000);

  // })

  useEffect(async () => {
    if (
      !router?.pathname?.includes("/post/") &&
      !router?.pathname?.includes("/video/")
    ) {
      const data = await fetch(
        `${baseUrl}/banner/all?source=WEB&category=floating-footer`
      );
      const amp = await data.json();
      const dat = amp?.result;
      setBottomBanner(dat);
    }
  }, [router?.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      console.log("changing scroll");

      setScrollY(window?.scrollY);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection]);
  useEffect(() => {
    if (router.pathname.includes("/short-video")) {
      setActiveTab("short-video");
    }
    setShowFooter(
      router.pathname !== "/login" &&
        router.pathname !== "/vs/header_lt" &&
        router.pathname !== "/adda/schedule" &&
        router.pathname !== "/cricket-page" &&
        router.pathname !== "/preferences" &&
        router.pathname !== "/loading/profile" &&
        !router.pathname.includes("/app-view") &&
        !router.pathname.includes("/liveblog") &&
        !router.pathname.includes("/faq-app") &&
        !router.pathname.includes("/quiz/terms-and-conditions") &&
        !router.pathname.includes("/amp/") &&
        !router?.query?.shortId?.length
    );
  }, [router.pathname]);

  var shortsTabClicked = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        limit: 1,
        skip: 1,
      }),
    };
    await fetch(
      `${process.env.NEXT_PUBLIC_APP_API_URL}/v1/short/list`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        var result = data?.result?.entities;
        console.log("lalala", result);
        window.location.href = `/short-video/${result[0].postSlug}`;
        // router.push(`/short-video/${result[0].postSlug}`);
      });
  };
  console.log("showfootermob", !router?.query?.shortId?.length);

  //Fatafat Logic

  useEffect(() => {
    function handleScroll() {
      setFirstScroll(true);
      window.removeEventListener("scroll", handleScroll);
    }

    window.addEventListener("scroll", handleScroll);
  }, []);

  const handleClick = () => {
    // pushGoogleTabEvent("homepage_fatafat_open", {});
    setShouldShow(false);
    router.push("/");
    // setShow((e) => !e);
  };
  const openHandler = () => {
    if (fatafatPosts && fatafatPosts?.length > 0) {
      setShouldShow(true);
      router.push(
        `?fatafat=${fatafatPosts[0]?.postSlug}&index=0`,
        `/${fatafatPosts[0]?.categorySlug}/post/${fatafatPosts[0]?.postSlug}`,
        { shallow: true }
      );
    } else {
      fatafatHandler();
      setShouldShow(true);
    }
  };

  const fatafatHandler = () => {
    fetch(`${baseUrl}/post/allFatafat?limit=9&skip=1&type=text`)
      .then((res) => res.json())
      .then((d) => {
        setFatafatPosts(d?.result?.entities);
      });
  };

  useEffect(() => {
    if (shouldShow) {
      openHandler();
    }
  }, []);

  return (
    <>
      {/* {router.asPath === "/"  && (
        <div className="stickyadcode" align="center">
          <Ads adName={"/1007232/Lallantop_HP_320x50_bottom"} />
        </div>
      )}
      {router.asPath !== "/" && (
        <div className="stickyadcode" align="center">
          <Ads adName={"/1007232/Lallantop_ROS_320x50_bottom"} />
        </div>
      )} */}
      {showFooter && (
        <>
          {scrollY > 170 && bottomBanner?.length > 0 && !closeFooterBanner && (
            <>
              {size?.width <= 768 && (
                <div
                  className="close-bottom-menu"
                  onClick={(event) => {
                    event.stopPropagation();
                    setCloseFooterBanner(true);
                    console.log("erman");
                  }}
                >
                  <span>X</span>
                </div>
              )}

              <a
                href={bottomBanner[0]?.deepLink}
                onClick={() => {
                  ga("create", "G-RXRPKR4310", "auto");
                  ga("send", "event", {
                    eventCategory: "banner",
                    eventAction: "Click",
                    eventLabel: "Footer-banner",
                    eventValue: `${bottomBanner[0]?.title}`,
                  });
                }}
              >
                <img
                  className="bottom-menu bottom-menu-banner"
                  src={bottomBanner[0]?.bannerImage}
                />
              </a>
            </>
          )}

          <div
            className={`bottom-menu ${
              scrollDirection === "down" ? "FooterShow" : "FooterAdHide"
            }`}
          >
            {
              <div className="container-row py-2" align="center">
                <p
                  style={{
                    fontSize: "11px",
                    color: "#b7b7b7",
                    textAlign: "center",
                    marginBottom: "0rem",
                  }}
                >
                  Advertisement
                </p>
                <div
                  id="div-gpt-ad-1701946324333-0"
                  style={{
                    minWidth: "300px",
                    minHeight: "70px",
                    display: "block !important",
                    alignContent: "center",
                    textAlign: "-webkit-center",
                  }}
                >
                  <script
                    type="text/javascript"
                    dangerouslySetInnerHTML={{
                      __html: `{googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946324333-0')})`,
                    }}
                  ></script>
                </div>
              </div>
            }
          </div>
          <div
            className={`bottom-menu ${
              scrollDirection === "down" ? "FooterHide" : "FooterShow"
            }`}
          >
            <ul>
              <li
                className={activeTab === "all" && !shouldShow ? "active" : ""}
              >
                {/* <a
                  href={{
                    pathname: "/",
                  }}
                  passHref
                > */}
                <a
                  href="/"
                  onClick={() => {
                    if (typeof window.ga !== "undefined") {
                      ga("create", "G-RXRPKR4310", "auto");
                      ga("send", "event", {
                        eventCategory: "bottom_menu",
                        eventAction: "Click",
                        eventLabel: "home",
                        eventValue: "https://www.thelallantop.com/",
                      });
                    }

                    setActiveTab("all");
                  }}
                >
                  {activeTab === "all" && !shouldShow ? (
                    <img
                      className="icon-home icon-padding"
                      src={
                        "https://static.thelallantop.com/images/post/1707895558725_home.webp?width=20&height=20"
                      }
                      width="20px"
                      height="20px"
                      alt="home"
                    />
                  ) : (
                    <img
                      className="icon-home icon-padding"
                      src={
                        "https://static.thelallantop.com/images/post/1707895509198_home_copy.webp?width=20&height=20"
                      }
                      width="20px"
                      height="20px"
                      alt="home"
                    />
                  )}
                  {/* <i className="icon-home icon-padding"></i> */}
                  <span>
                    <FormattedMessage id="home" defaultMessage="होम" />
                  </span>
                </a>
                {/* </a> */}
              </li>
              {/* <li className={activeTab === "text" ? "active" : ""}>
                <a
                  href={{
                    pathname: `/text`,
                  }}
                  passHref
                >
                  <a
                    onClick={() => {
                      // ClevertapReact.event("Footer_Read");
                      if (typeof window.ga !== "undefined") {
                        ga("create", "G-RXRPKR4310", "auto");
                        ga("send", "event", {
                          eventCategory: "bottom_menu",
                          eventAction: "Click",
                          eventLabel: "text",
                          eventValue: "https://www.thelallantop.com/text",
                        });
                      }

                      setActiveTab("text");
                    }}
                  >
                    {activeTab === "text" ? (
                      <img
                        className="icon-b-read icon-padding"
                        src="/assets/images/i2/Read.png"
                        width="20px"
                        height="20px"
                        alt="home"
                      />
                    ) : (
                      <img
                        className="icon-b-read icon-padding"
                        src="/assets/images/i1/Read1.png"
                        width="20px"
                        height="20px"
                        alt="home"
                      />
                    )}
                    <span>
                      <FormattedMessage id="readE" defaultMessage="पढ़िए" />
                    </span>
                  </a>
                </a>
              </li> */}
              <li className={shouldShow ? "active" : ""}>
                {/* <a
                  href={{
                    pathname: "/quiz/live",
                  }}
                  passHref
                > */}
                <a
                  href="/?fatafat=true"
                  // onClick={() => {
                  //   // ClevertapReact.event("footer_videos");
                  //   if (typeof window.ga !== "undefined") {
                  //     ga("create", "G-RXRPKR4310", "auto");
                  //     ga("send", "event", {
                  //       eventCategory: "bottom_menu",
                  //       eventAction: "Click",
                  //       eventLabel: "quiz",
                  //       eventValue: "https://www.thelallantop.com/quiz/live",
                  //     });
                  //   }
                  //   setActiveTab("quiz");
                  // }}
                  // onClick={openHandler}
                >
                  {shouldShow ? (
                    <img
                      className="icon-vidos icon-padding"
                      src={
                        "https://static.thelallantop.com/images/post/1719994948961_fatafat_pink.webp?width=20&height=20"
                      }
                      width="20px"
                      height="20px"
                      alt="home"
                    />
                  ) : (
                    <img
                      className="icon-vidos icon-padding"
                      src={
                        "https://static.thelallantop.com/images/post/1719994936583_fatafat_black.webp?width=20&height=20"
                      }
                      width="20px"
                      height="20px"
                      alt="home"
                    />
                  )}
                  <span style={{ color: shouldShow ? "#D9368B" : "black" }}>
                    <FormattedMessage id="fatafat" defaultMessage="देखिए" />
                  </span>
                </a>
                {/* </a> */}
              </li>
              <li
                className={activeTab === "audio" && !shouldShow ? "active" : ""}
              >
                {/* <a
                  href={{
                    pathname: `/audio`,
                  }}
                  passHref
                > */}
                <a
                  href="/audio"
                  onClick={() => {
                    // ClevertapReact.event("Footer_Listen");
                    if (typeof window.ga !== "undefined") {
                      ga("create", "G-RXRPKR4310", "auto");
                      ga("send", "event", {
                        eventCategory: "bottom_menu",
                        eventAction: "Click",
                        eventLabel: "audio",
                        eventValue: "https://www.thelallantop.com/audio",
                      });
                    }
                    setActiveTab("audio");
                  }}
                >
                  {activeTab === "audio" && !shouldShow ? (
                    <img
                      className="icon-listen icon-padding"
                      src={
                        "https://static.thelallantop.com/images/post/1707895604261_microphone_copy.webp?width=20&height=20"
                      }
                      width="20px"
                      height="20px"
                      alt="home"
                    />
                  ) : (
                    <img
                      className="icon-listen icon-padding"
                      src={
                        "https://static.thelallantop.com/images/post/1707895619551_microphone.webp?width=20&height=20"
                      }
                      width="20px"
                      height="20px"
                      alt="home"
                    />
                  )}
                  {/* <i className="icon-listen icon-padding"></i> */}
                  <span>
                    <FormattedMessage id="audios" defaultMessage="सुनिए" />
                  </span>
                </a>
                {/* </a> */}
              </li>
              <li
                className={
                  activeTab === "short-video" && !shouldShow ? "active" : ""
                }
              >
                {/* <a href="/short-video" passHref> */}
                <a
                  // href="/short-video"
                  onClick={() => {
                    if (typeof window.ga !== "undefined") {
                      ga("create", "G-RXRPKR4310", "auto");
                      ga("send", "event", {
                        eventCategory: "bottom_menu",
                        eventAction: "Click",
                        eventLabel: "shorts",
                        eventValue: "https://www.thelallantop.com/short-video",
                      });
                    }
                    shortsTabClicked();
                  }}
                >
                  {activeTab === "short-video" && !shouldShow ? (
                    <img
                      className="icon-shows icon-padding"
                      src={
                        "https://static.thelallantop.com/images/post/1707895644308_note.webp?width=20&height=20"
                      }
                      width="20px"
                      height="20px"
                      alt="home"
                    />
                  ) : (
                    <img
                      className="icon-shows icon-padding"
                      src={
                        "https://static.thelallantop.com/images/post/1707895631940_note-2.webp?width=20&height=20"
                      }
                      width="20px"
                      height="20px"
                      alt="home"
                    />
                  )}
                  {/* <i className="icon-shows icon-padding"></i> */}
                  <span>
                    <FormattedMessage id="shorts" defaultMessage="शॉर्ट्स" />
                  </span>
                </a>
                {/* </a> */}
              </li>
              <li
                className={activeTab === "show" && !shouldShow ? "active" : ""}
              >
                {/* <a href="/show/all" passHref> */}
                <a
                  href="/show/all"
                  onClick={() => {
                    // ClevertapReact.event("Footer_explore", {
                    //   "Content Type": "Top Shows",
                    // });
                    if (typeof window.ga !== "undefined") {
                      ga("create", "G-RXRPKR4310", "auto");
                      ga("send", "event", {
                        eventCategory: "bottom_menu",
                        eventAction: "Click",
                        eventLabel: "shows",
                        eventValue: "https://www.thelallantop.com/show/all",
                      });
                    }
                    setActiveTab("show");
                  }}
                >
                  {activeTab === "show" && !shouldShow ? (
                    <img
                      className="icon-shows icon-padding"
                      src={
                        "https://static.thelallantop.com/images/post/1707895662877_video-play_copy.webp?width=20&height=20"
                      }
                      width="20px"
                      height="20px"
                      alt="home"
                    />
                  ) : (
                    <img
                      className="icon-shows icon-padding"
                      src={
                        "https://static.thelallantop.com/images/post/1707895654188_video-play.webp?width=20&height=20"
                      }
                      width="20px"
                      height="20px"
                      alt="home"
                    />
                  )}
                  {/* <i className="icon-shows icon-padding"></i> */}
                  <span>
                    <FormattedMessage id="shows" defaultMessage="शो" />
                  </span>
                </a>
                {/* </a> */}
              </li>
              {/* <li>
            <a href="/quiz" passHref>
              <a>
                <i className="icon-quiz"></i>
                <span><FormattedMessage id="quiz" defaultMessage="क्विज" /></span>
              </a>
            </a>
          </li> */}
            </ul>
          </div>
        </>
      )}
      {fatafatPosts && fatafatPosts.length > 0 && shouldShow && (
        <FtafatModal
          show={shouldShow}
          fatafatPosts1={fatafatPosts}
          onClick={() => {
            handleClick();
          }}
          queryIndex={queryIndex}
        />
      )}
    </>
  );
};
export default MobileMenuBottom;
